<template>
  <v-container>
    <the-breadcrumb></the-breadcrumb>

    <div class="d-flex flex-column flex-md-row mt-3">
      <div>
        <indices-nav @itemSeleccionado="cambiarPanel($event)"></indices-nav>
      </div>
      <div class="flex-fill ml-md-3 ">
        <indices-view-tipo
            :itemNav="itemNav"
        ></indices-view-tipo>
      </div>
    </div>


  </v-container>
</template>

<script>
import TheBreadcrumb from "@/components/Breadcrumb/TheBreadcrumb";
import IndicesNav from "@/views/index/indices/IndicesNav";
import IndicesViewTipo from "@/views/index/indices/IndicesTipo";
import  {ID_TIPO_REGISTRO} from "@/model/registros/registros.entity";

export default {
  name: "IndicesView",
  components: {IndicesViewTipo, IndicesNav, TheBreadcrumb},
  data() {
    return {
      itemNav: null,
      lista_registros: [],
      url_categoria: null,
      cargandoCategorias: false
    }
  },
  watch: {
    getIdTipoRegistro(){
console.log("ejecuta el cambio de ruta")
      let registro = "";

      switch (this.itemNav.id_tipo_registro) {
        case ID_TIPO_REGISTRO.REGISTRO_DE_PROPIEDAD:
          registro = "propiedad"
          break;
        case ID_TIPO_REGISTRO.COMERCIO:
          registro = "comercio"
          break;
          case ID_TIPO_REGISTRO.AGUAS:
            registro = "aguas"
              break;
        default:
          registro = "no-definido"
      }
      let url = "/indices/" + registro;

      // Para evitar el warn de rutas duplicadas
      if (this.$route.path !== url) {
        this.$router.replace("/indices/" + registro);
      }
    }
  },
  methods: {
    cambiarPanel(panel) {
      this.itemNav = panel;
    }
  },
  computed:{
    getIdTipoRegistro(){
      if(this.itemNav != null){
        return this.itemNav.id_tipo_registro;
      }else{
        return 0;
      }

    }
  }

}
</script>

<style scoped>

</style>